import React from "react";
import SmoothScroll from "smooth-scroll";
import { About } from "./components/About";
import { Contact } from "./components/Contact";
import { FAQs } from "./components/FAQs";
import { Header } from "./components/Header";
import { Navigation } from "./components/Navigation";
import { Services } from "./components/Services";
import { Team } from "./components/Team";

import "./App.css";
import Footer from "./components/Footer";

import data from "./data/data.json";
import { Blog } from "./components/Blog";
const { header, contact, about, services, team, faqs, blog } = data;

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export default function App() {
  return (
    <div>
      <Navigation />
      <Header {...header} />
      <Contact {...contact} /> {/* blue */}
      <About {...about} /> {/* white */}
      <Blog data={blog} /> {/* blue */}
      <Team data={team} /> {/* white */}
      <Services data={services} /> {/* blue */}
      <FAQs data={faqs} /> {/* white */}
      <Footer />
    </div>
  );
}
