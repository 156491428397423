import React from "react";
export const Image = ({ title, slug, largeImage, desc }) => {
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {" "}
        <a href={slug} title={title} data-lightbox-gallery="gallery1">
          <div className="hover-text">
            <br />
            <br />
            <br />
            <h4>{desc}</h4>
            <h4>Read More</h4>
          </div>
          <img src={largeImage} className="img-responsive" alt={title} />{" "}
        </a>{" "}
      </div>
    </div>
  );
};
