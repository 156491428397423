import React from "react";
import { Image } from "./Image";

export const Blog = (props) => {
  return (
    <div id="blog" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Blog</h2>
        </div>
        <div className="row">
          <div className="blog-items">
            {props.data ? (
              props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-sm-6 col-md-4 col-lg-4"
                >
                  <Image
                    title={d.title}
                    largeImage={d.largeImage}
                    slug={d.slug}
                    desc={d.date}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
